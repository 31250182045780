<template>
	<div v-if="isVisible" class="modal-container">
		<div class="content">
			<div class="modal-navibar">
				<div class="modal-navibar-title">
					AD
				</div>
				<div class="close-btn" @click="closeModal"></div>
			</div>

			<div class="modal-ad-cover" :style="{ backgroundImage: 'url(' + $root.adDes.bgd_url + ')', backgroundSize: 'cover' }" :class="{'withbg':$root.adDes.bgd_url}" @click="gotoAD($root.adDes.jumpUrl)" v-if="$root.adDes">
				<div class="ad-icon">AD</div>
				<img class="ad-cover-icon" :src="$root.adDes.icon_url" :alt="$root.adDes.title || ''" v-if="$root.adDes && $root.adDes.icon_url">

				<div class="ad-cover-title" :class="{'nobg':!$root.adDes.bgd_url}" v-html="$root.adDes.title" v-if="$root.adDes && $root.adDes.title"></div>
				<div class="ad-cover-desc" :class="{'nobg':!$root.adDes.bgd_url}" v-html="$root.adDes.desc" v-if="$root.adDes && $root.adDes.desc"></div>
				<div class="ad-cover-btn" v-html="$root.adDes.btn" v-if="$root.adDes && $root.adDes.btn"></div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	props: ["isVisible"],
	methods: {
		closeModal() {
			this.$emit("close");
		},
		gotoAD(data) {
			this.$logEvent(`click_modal_banner`);
			window.location.href = data;
		},
	},
};
</script>
