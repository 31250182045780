<template>
	<section id="section-apk-detail" class="section-content">
		<h2 class="intro-title intro-title-2" v-html="doc.apkdetail.title_1"></h2>

		<table id="apkdetail" cellspacing="0">
			<tbody>
				<tr>
					<td>
						<div>App Name</div>
					</td>
					<td>

						<span>GBWhatsApp APK</span>
					</td>
				</tr>
				<tr v-for="(data, i) in apkdetailList" :key="i">
					<td>
						<div v-html="data.name"></div>
					</td>
					<td>
						<div v-html="data.value"></div>
					</td>
				</tr>
				<tr>
					<td>
						<div>RATING</div>
					</td>
					<td>
						<div v-if="$root.initData && $root.initData.settings">
							<span>{{ $root.initData.settings.score }}</span> ( {{ $root.initData.settings.totalCount }} ratings )
						</div>
						<div v-else>
							<span>3.65</span> ( 46258 ratings )
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>
export default {
	name: "apkdetail",
	data() {
		return {
			apkdetailList: [],
		};
	},
	props: {
		doc: Object,
	},
	mounted() {
		this.apkdetailList = [
			{
				name: "Software Category",
				value: "APP",
				itemprop: "applicationCategory",
				content: "MobileApplication",
			},
			{
				name: "App size",
				value: "51.1 MB",
			},
			{
				name: "Version",
				value: "Latest Version",
				itemprop: "version",
			},
			{
				name: "Last Update",
				value: "6 Hour Ago",
				itemprop: "update",
			},
			{
				name: "Total Downloads",
				value: "75,000,000+",
				itemprop: "downloads",
			},
			{
				name: "Android Version",
				value: "5.1 and Above",
			},
			{
				name: "Root Requirement",
				value: "Non Root",
			},
		];
	},
};
</script>
