const allCommentList = [
	{
		name: 'Logan',
		time: 'December 15, 2023, 09:20',
		content: 'I like gbwhatsapp among many versions.',
	},
	{
		name: 'Joko',
		time: 'December 17, 2023, 12:33',
		content: 'gb whatsapp and gbwhatsapp pro have the same functions and are very easy to use.',
	},
	{
		name: 'Arjun Patel',
		time: 'December 18, 2023, 07:55',
		content: "I can download other people's statuses. That's great.",
	},
	{
		name: 'Budi Setiawan',
		time: 'December 19, 2023, 10:30',
		content: 'I have been using gbwhatsApp for a while! It feels pretty good.',
	},
	{
		name: 'Budi Santoso',
		time: 'December 21, 2023, 09:35',
		content: 'gbwhatsapp is a reliable messaging app. It gets the job done efficiently.',
	},
	{
		name: 'Priya',
		time: 'December 22, 2023, 12:45',
		content: "it's great.",
	},
	{
		name: 'Rajesh Patel',
		time: 'December 24, 2023, 16:21',
		content: "gb WhatsApp provides a good set of features. It's a convenient messaging app overall.",
	},
	{
		name: 'Siti Wahyuni',
		time: 'December 25, 2023, 10:55',
		content: "The latest update of gb whatsapp improved the performance and stability. It's working smoothly.",
	},
	{
		name: 'Siska Halim',
		time: 'December 28, 2023, 04:14',
		content: 'I appreciate the privacy options in gbwhatsapp. I feel more secure with my conversations.',
	},
	{
		name: 'Putri Sari',
		time: 'December 29, 2023, 11:45',
		content: 'I want to download gb whatsapp app.',
	},
	{
		name: 'Dewi Lestari',
		time: 'December 29, 2023, 14:20',
		content: 'I want to know how should I update my gb whatsapp.',
	},
	{
		name: 'Aashi Gupta',
		time: 'December 30, 2023, 11:16',
		content: "If I hide status, can people really not see if I'm online?",
	},
	{
		name: 'Rajiv Sharma',
		time: 'January 1, 2024, 05:30',
		content: 'I love this app.',
	},
	{
		name: 'Ashok Verma',
		time: 'January 2, 2024, 11:15',
		content: 'Good.',
	},
	{
		name: 'Saira Khan',
		time: 'January 2, 2024, 09:45',
		content: 'I want to share this app with my friends.',
	},
	{
		name: 'Saira Khan',
		time: 'January 3, 2024, 19:45',
		content: 'This app safe ? I want to use it.',
	},
	{
		name: 'Rani Indriani',
		time: 'January 4, 2024, 01:25',
		content: 'How to download this app.',
	},
	{
		name: 'Rahul',
		time: 'January 5, 2024, 09:50',
		content: 'gb whatsapp has a few minor bugs occasionally.',
	},
	{
		name: 'Neha Sharma',
		time: 'January 7, 2024, 01:15',
		content: "I'm satisfied with gb whatsapp.",
	},
	{
		name: 'Deepak Verma',
		time: 'January 7, 2024, 08:40',
		content: "gb whatsapp has a clean and user-friendly interface. It's easy to navigate and find what I need.",
	},
	{
		name: 'Adi Nugroho',
		time: 'January 10, 2024, 03:25',
		content: "I've recommended GB WhatsApp to my friends.",
	},
	{
		name: 'Dewi Kusuma',
		time: 'January 12, 2024, 11:25',
		content: 'Gbwhatsapp occasionally drains more battery than expected. Hoping for better optimization in the future updates.',
	},
	{
		name: 'Rajesh',
		time: 'January 13, 2024, 23:50',
		content: "Gbwhatsapp's chat backup feature is a lifesaver.",
	},
	{
		name: 'Akbar',
		time: 'January 15, 2024, 10:25',
		content: "Gb WhatsApp's message scheduling feature is handy for sending reminders at the right time. I find it useful.",
	},
	{
		name: 'Indah Purnomo',
		time: 'January 16, 2024, 03:50',
		content: "Gbwhatsapp's privacy settings give me control over who can see my profile.",
	},
	{
		name: 'Harsha',
		time: 'January 18, 2024, 18:15',
		content: "gb WhatsApp's theming options allow me to personalize the app's appearance.",
	},
	{
		name: 'Rina',
		time: 'January 21, 2024, 12:40',
		content: "GB WhatsApp's file sharing capabilities make it easy to exchange documents and media with friends.",
	},
	{
		name: 'Prakash',
		time: 'January 22, 2024, 03:29',
		content: 'A reliable messaging app.',
	},
	{
		name: 'Rika Fitriani',
		time: 'January 25, 2024, 10:00',
		content: "I like GB WhatsApp's anti-revoke feature quite useful at times.",
	},
	{
		name: 'Ahmad',
		time: 'January 26, 2024, 02:25',
		content: 'The font customization options in gbwhatsapp are a nice touch.',
	},
	{
		name: 'Priya',
		time: 'January 29, 2024, 22:23',
		content: "Gb WhatsApp occasionally lags when handling multiple chats, but it's still my preferred app.",
	},
	{
		name: 'Kavita Saxena',
		time: 'January 30, 2024, 18:05',
		content: "Gbwhatsapp's group chat is great.",
	},
	{
		name: 'Sunil Shah',
		time: 'February 1, 2024, 20:35',
		content: "GB WhatsApp is my favorite messaging app. It's reliable and has a good set of features.",
	},
	{
		name: 'Rohit Mehta',
		time: 'February 3, 2024, 13:55',
		content: 'The seamless transition from regular WhatsApp to GB WhatsApp is impressive. No data loss during the switch.',
	},
	{
		name: 'Priya',
		time: 'February 4, 2024, 11:20',
		content: "Gb whatsapp's message formatting options add a touch of creativity to my conversations. I enjoy using them.",
	},
	{
		name: 'Aryan',
		time: 'February 6, 2024, 04:46',
		content: "It's generally reliable.",
	},
	{
		name: 'Manoj Chatterjee',
		time: 'February 7, 2024, 03:55',
		content: 'I enjoy using it.',
	},
	{
		name: 'Dhruv',
		time: 'February 10, 2024, 09:32',
		content: "I appreciate gb whatsapp's regular updates.",
	},
	{
		name: 'Maya',
		time: 'February 11, 2024, 02:05',
		content: 'Gb WhatsApp is a good app.',
	},
	{
		name: 'Sunita Patel',
		time: 'February 14, 2024, 17:40',
		content: "I like the ability to customize GB WhatsApp's theme.",
	},
	{
		name: 'Budi Santoso',
		time: 'February 17, 2024, 12:28',
		content: "GBWhatsApp occasionally uses more battery, but it's a small trade-off for the features it offers.",
	},
	{
		name: 'Shalini Bhatia',
		time: 'February 21, 2024, 16:01',
		content: 'Gb WhatsApp is my favorite app.',
	},
	{
		name: 'Raj',
		time: 'February 25, 2024, 10:35',
		content: "Gb WhatsApp's chat backup feature good.",
	},
	{
		name: 'Siti Rahayu',
		time: 'February 25, 2024, 13:45',
		content: 'I like the customization that gb whatsApp offers, being able to adjust the layout and colors of the chat interface myself.',
	},
	{
		name: 'Mason',
		time: 'February 28, 2024, 08:15',
		content: 'When will GBWhatsApp be updated to add new features?',
	},
	{
		name: 'Sophia',
		time: 'March 1, 2024, 19:00',
		content: 'Gb whatsapp is very good app.',
	},
	{
		name: 'Laila',
		time: 'March 3, 2024, 03:10',
		content: 'I feel safe using it.',
	},
	{
		name: 'Puja Krishnan',
		time: 'March 5, 2024, 08:44',
		content: "Gb WhatsApp's message scheduling feature is handy for sending reminders and birthday wishes on time.",
	},
	{
		name: 'Anita',
		time: 'March 5, 2024, 01:22',
		content: 'Hide online status gives me more privacy.',
	},
	{
		name: 'Amit',
		time: 'March 9, 2024, 15:55',
		content: "Gb whatsapp, It's impressive.",
	},
	{
		name: 'Patel',
		time: 'March 5, 2024, 21:22',
		content: 'The gbwhatsapp downloaded from here can really be used normally, thank you！',
	},
	{
		name: 'Kumar',
		time: 'March 5, 2024, 19:12',
		content: 'My account can be used normally. Thank you very much.',
	},
	{
		name: 'Gupta',
		time: 'March 5, 2024, 17:08',
		content: 'My gb account can be logged in successfully.',
	},
	{
		name: 'Anjali',
		time: 'March 5, 2024, 08:19',
		content: 'My gbwhatsapp account was banned before. But now, it can log in successfully.',
	},

	{
		name: 'Radha',
		time: 'March 11, 2024, 10:30 ',
		content: "I find gb whatsapp's fonts and text formatting options delightful. It adds character to my chats.",
	},
	{
		name: 'Aditya',
		time: 'March 13, 2024, 03:05',
		content: 'Gb occasionally takes longer to load.',
	},
	{
		name: 'Anjali Joshi',
		time: 'March 13, 2024, 17:43',
		content: 'The video call quality in gb whatsapp is impressive.',
	},
	{
		name: 'Vivek',
		time: 'March 16, 2024, 12:15',
		content: 'Anti-revoke feature is useful. I can view deleted messages.',
	},
	{
		name: 'Vikram Reddy',
		time: 'March 20, 2024, 14:50',
		content: 'I enjoy to use it.',
	},
	{
		name: 'Laila',
		time: 'March 23, 2024, 03:10',
		content: 'I feel safe using it.',
	},
	{
		name: 'Puja Krishnan',
		time: 'March 25, 2024, 08:45',
		content: "Gb WhatsApp's message scheduling feature is handy for sending reminders and birthday wishes on time.",
	},
	{
		name: 'Anita',
		time: 'March 25, 2024, 01:26',
		content: 'Hide online status gives me more privacy.',
	},
	{
		name: 'Amit',
		time: 'March 26, 2024, 15:55',
		content: "Gb whatsapp, It's impressive.",
	},
	{
		name: 'Radha',
		time: 'March 27, 2024, 10:30 ',
		content: "I find gb whatsapp's fonts and text formatting options delightful. It adds character to my chats.",
	},
	{
		name: 'Aditya',
		time: 'March 28, 2024, 13:05',
		content: 'Gb occasionally takes longer to load.',
	},
	{
		name: 'Anjali Joshi',
		time: 'March 28, 2024, 17:40',
		content: 'The video call quality in gb whatsapp is impressive.',
	},
	{
		name: 'Vivek',
		time: 'March 29, 2024, 12:15',
		content: 'Anti-revoke feature is useful. I can view deleted messages.',
	},
	{
		name: 'Vikram Reddy',
		time: 'March 30, 2024, 04:50',
		content: 'I enjoy to use it.',
	},
	{
		name: 'Rajesh',
		time: 'April 2, 2024, 09:25',
		content: 'good.',
	},
	{
		name: 'Ravi Malhotra',
		time: 'April 3, 2024, 02:00',
		content: "Gb WhatsApp's message formatting options add a touch of creativity to my conversations. It's fun.",
	},
	{
		name: 'Pooja Desai',
		time: 'April 6, 2024, 06:35',
		content: 'I like to use gbwhatsapp.',
	},
	{
		name: 'Logan',
		time: 'April 7, 2024, 06:00',
		content: 'I chose gbwhatsapp among many versions.',
	},
	{
		name: 'Joko Widodo',
		time: 'April 8, 2024, 2:34',
		content: 'gb whatsapp and gbwhatsapp pro have the same functions and are very easy to use.',
	},
	{
		name: 'Arjun Patel',
		time: 'April 10, 2024, 17:55',
		content: "I can download other people's statuses. That's great.",
	},

	{
		name: 'Rina Sari',
		time: 'April 12, 2024, 14:45',
		content: 'The voice and video call quality in gb whatsapp is impressive. Clear sound and smooth video experience.',
	},
	{
		name: 'Akbar',
		time: 'April 13, 2024, 10:25',
		content: "Gb WhatsApp's message scheduling feature is handy for sending reminders at the right time. I find it useful.",
	},
	{
		name: 'Indah Purnomo',
		time: 'April 15, 2024, 03:50',
		content: "Gbwhatsapp's privacy settings give me control over who can see my profile.",
	},
	{
		name: 'Harsha',
		time: 'April 15, 2024, 18:15',
		content: "gb WhatsApp's theming options allow me to personalize the app's appearance.",
	},
	{
		name: 'Rina',
		time: 'April 18, 2024, 12:42',
		content: "GB WhatsApp's file sharing capabilities make it easy to exchange documents and media with friends.",
	},
	{
		name: 'Prakash',
		time: 'April 20, 2024, 13:20',
		content: 'A reliable messaging app.',
	},
	{
		name: 'Rika Fitriani',
		time: 'April 24, 2024, 10:04',
		content: "I like GB WhatsApp's anti-revoke feature quite useful at times.",
	},
	{
		name: 'Ahmad',
		time: 'April 25, 2024, 22:25',
		content: 'The font customization options in gbwhatsapp are a nice touch.',
	},
	{
		name: 'Priya',
		time: 'April 29, 2024, 12:24',
		content: "Gb WhatsApp occasionally lags when handling multiple chats, but it's still my preferred app.",
	},
	{
		name: 'Kavita Saxena',
		time: 'May 1, 2024, 08:05',
		content: "Gbwhatsapp's group chat is great.",
	},
	{
		name: 'Kiran',
		time: 'May 4, 2024, 12:52',
		content: 'A reliable messaging app.',
	},
	{
		name: 'Sita',
		time: 'May 5, 2024, 05:25',
		content: "GB WhatsApp's file sharing feature simplifies sharing documents and media with my contacts.",
	},
	{
		name: 'Naina Kapoor',
		time: 'May 7, 2024, 11:13',
		content: "I find gb whatsapp's interface more intuitive compared to other messaging apps.",
	},
	{
		name: 'Meera',
		time: 'May 10, 2024, 11:05',
		content: 'GB WhatsApp has a great feature set.',
	},
	{
		name: 'Rini Widjaja',
		time: 'May 11, 2024, 04:34',
		content: 'I enjoy using it.',
	},
	{
		name: 'Arjun',
		time: 'May 12, 2024, 19:52',
		content: "I appreciate the ability to lock individual chats in gb whatsapp for added privacy. It's a useful feature.",
	},
	{
		name: 'Ananya',
		time: 'May 12, 2024, 21:15',
		content: 'The developers care about improving the user experience.',
	},
	{
		name: 'Imran',
		time: 'May 13, 2024, 08:44',
		content: 'I find it easy to use GB WhatsApp.',
	},
	{
		name: 'Kiran',
		time: 'May 17, 2024, 01:05',
		content: 'I love the ability to pin important chats in GB WhatsApp. It keeps my priority conversations at the top.',
	},
	{
		name: 'Sunil Shah',
		time: 'May 18, 2024, 10:35',
		content: "GB WhatsApp is my favorite messaging app. It's reliable and has a good set of features.",
	},
	{
		name: 'Rohit Mehta',
		time: 'May 22, 2024, 03:55',
		content: 'The seamless transition from regular WhatsApp to GB WhatsApp is impressive. No data loss during the switch.',
	},
	{
		name: 'Priya',
		time: 'May 25, 2024, 11:20',
		content: "Gb whatsapp's message formatting options add a touch of creativity to my conversations. I enjoy using them.",
	},
	{
		name: 'Aryan',
		time: 'May 26, 2024, 14:45',
		content: "It's generally reliable.",
	},
	{
		name: 'Manoj Chatterjee',
		time: 'May 26, 2024, 23:55',
		content: 'I enjoy using it.',
	},
	{
		name: 'Dhruv',
		time: 'May 29, 2024, 09:30',
		content: "I appreciate gb whatsapp's regular updates.",
	},
	{
		name: 'Maya',
		time: 'June 1, 2024, 02:05',
		content: 'Gb WhatsApp is a good app.',
	},
	{
		name: 'Sunita Patel',
		time: 'June 4, 2024, 07:40',
		content: "I like the ability to customize GB WhatsApp's theme.",
	},
	{
		name: 'Budi Santoso',
		time: 'June 6, 2024, 01:25',
		content: "GBWhatsApp occasionally uses more battery, but it's a small trade-off for the features it offers.",
	},
	{
		name: 'Shalini Bhatia',
		time: 'June 7, 2024, 06:10',
		content: 'Gb WhatsApp is my favorite app.',
	},
	{
		name: 'Raj',
		time: 'June 8, 2024, 10:35',
		content: "Gb WhatsApp's chat backup feature good.",
	},
	{
		name: 'Rajesh',
		time: 'June 10, 2024, 09:25',
		content: 'good.',
	},
	{
		name: 'Ravi Malhotra',
		time: 'June 13, 2024, 02:00',
		content: "Gb WhatsApp's message formatting options add a touch of creativity to my conversations. It's fun.",
	},
	{
		name: 'Pooja Desai',
		time: 'June 13, 2024, 06:35',
		content: 'I like to use gbwhatsapp.',
	},
	{
		name: 'Logan',
		time: 'June 15, 2024, 06:00',
		content: 'I chose gbwhatsapp among many versions.',
	},
	{
		name: 'Joko Widodo',
		time: 'June 20, 2024, 02:30',
		content: 'gb whatsapp and gbwhatsapp pro have the same functions and are very easy to use.',
	},
	{
		name: 'Arjun Patel',
		time: 'June 21, 2024, 07:55',
		content: "I can download other people's statuses. That's great.",
	},
	{
		name: 'Budi Setiawan',
		time: 'June 24, 2024, 10:30',
		content: 'I have been using gbwhatsApp for a while! It feels pretty good.',
	},
	{
		name: 'Budi Santoso',
		time: 'June 27, 2024, 09:30',
		content: 'gbwhatsapp is a reliable messaging app. It gets the job done efficiently.',
	},
	{
		name: 'Priya',
		time: 'June 28, 2024, 02:45',
		content: "it's great.",
	},
	{
		name: 'Rajesh Patel',
		time: 'July 2, 2024, 06:20',
		content: "gb WhatsApp provides a good set of features. It's a convenient messaging app overall.",
	},
	{
		name: 'Siti Wahyuni',
		time: 'July 5, 2024, 10:55',
		content: "The latest update of gb whatsapp improved the performance and stability. It's working smoothly.",
	},
	{
		name: 'Siska Halim',
		time: 'July 5, 2024, 14:13',
		content: 'I appreciate the privacy options in gbwhatsapp. I feel more secure with my conversations.',
	},
	{
		name: 'Putri Sari',
		time: 'July 6, 2024, 11:45',
		content: 'I want to download gb whatsapp app.',
	},
	{
		name: 'Dewi Lestari',
		time: 'July 8, 2024, 4:20',
		content: 'I want to know how should I update my gb whatsapp.',
	},
	{
		name: 'Aashi Gupta',
		time: 'July 8, 2024, 5:16',
		content: "If I hide status, can people really not see if I'm online?",
	},
	{
		name: 'Rajiv Sharma',
		time: 'July 10, 2024, 05:30',
		content: 'I love this app.',
	},
	{
		name: 'Ashok Verma',
		time: 'July 11, 2024, 11:15',
		content: 'Good.',
	},
	{
		name: 'Saira Khan',
		time: 'July 15, 2024, 09:45',
		content: 'I want to share this app with my friends.',
	},
	{
		name: 'Saira Khan',
		time: 'July 17, 2024, 09:48',
		content: 'This app safe ? I want to use it.',
	},
	{
		name: 'Rani Indriani',
		time: 'July 20, 2024, 01:25',
		content: 'How to download this app.',
	},
	{
		name: 'Rahul',
		time: 'July 21, 2024, 09:57',
		content: 'gb whatsapp has a few minor bugs occasionally.',
	},
	{
		name: 'Neha Sharma',
		time: 'July 24, 2024, 01:15',
		content: "I'm satisfied with gb whatsapp.",
	},
	{
		name: 'Deepak Verma',
		time: 'July 28, 2024, 08:40',
		content: "gb whatsapp has a clean and user-friendly interface. It's easy to navigate and find what I need.",
	},
	{
		name: 'Adi Nugroho',
		time: 'July 28, 2024, 13:25',
		content: "I've recommended GB WhatsApp to my friends.",
	},
	{
		name: 'Dewi Kusuma',
		time: 'July 30, 2024, 11:05',
		content: 'Gbwhatsapp occasionally drains more battery than expected. Hoping for better optimization in the future updates.',
	},
	{
		name: 'Rajesh',
		time: 'August 2, 2024, 03:50',
		content: "Gbwhatsapp's chat backup feature is a lifesaver.",
	},
	{
		name: 'Priya Kapoor',
		time: 'August 3, 2024, 12:15',
		content: 'I appreciate the customization options in gb whatsapp. It allows me to personalize my chats.',
	},
	{
		name: 'Jaya Singhania',
		time: 'August 5, 2024, 18:05',
		content: 'I want to know how to use gb whatsapp channel.',
	},
	{
		name: 'Aryan Desai',
		time: 'August 6, 2024, 16:30',
		content: 'My friend told me that I can download safe gb whatsapp here.',
	},
	{
		name: 'Priya Mehra',
		time: 'August 8, 2024, 02:54',
		content: 'Good app gb whatsapp.',
	},
	{
		name: 'Adi Santoso',
		time: 'August 9, 2024, 04:10',
		content: 'This app safe ? I want to use it.',
	},
	{
		name: 'Aryan',
		time: 'August 11, 2024, 05:35',
		content: "Gb WhatsApp is my go-to messaging app. It's reliable, and I haven't faced any major issues.",
	},
	{
		name: 'Rahmat Suryanto',
		time: 'August 12, 2024, 11:15',
		content: 'The dark mode feature in GB WhatsApp is fantastic.',
	},
	{
		name: 'Sanjay',
		time: 'August 13, 2024, 03:55',
		content: "Gb WhatsApp's group chat functionality is great for coordinating with my friends.",
	},
	{
		name: 'Maya',
		time: 'August 15, 2024, 09:36',
		content: "Gb WhatsApp occasionally lags when handling a large number of chats, but it's still usable.",
	},
	{
		name: 'Aditya',
		time: 'August 17, 2024, 03:33',
		content: "Gb WhatsApp is my go-to messaging app. It's reliable and easy to use.",
	},
	{
		name: 'Ravi',
		time: 'August 20, 2024, 08:20',
		content: "It's been a smooth experience overall.",
	},
	{
		name: 'Faisal',
		time: 'August 21, 2024, 01:45',
		content: 'Gb WhatsApp offers a great set of features.',
	},
	{
		name: 'Nita',
		time: 'August 22, 2024, 07:10',
		content: 'I appreciate the privacy options in GB WhatsApp. It allows me to control who sees my profile.',
	},
	{
		name: 'Rahul',
		time: 'August 25, 2024, 11:55',
		content: "GB WhatsApp occasionally has minor glitches, but they're quickly resolved in updates.",
	},
	{
		name: 'Siti Wahyuni',
		time: 'August 28, 2024, 04:40',
		content: 'Gb WhatsApp has a user-friendly interface, making it easy to navigate and find what I need.',
	},
	{
		name: 'Vinod',
		time: 'August 30, 2024, 10:15',
		content: "I've recommended gb whatsapp to my friends.",
	},
	{
		name: 'Anjali',
		time: 'September 1, 2024, 03:50',
		content: 'The voice and video call quality in gbwhatsapp are impressive.',
	},
	{
		name: 'Endang Susilo',
		time: 'September 5, 2024, 09:35',
		content: "Gb WhatsApp's customization options allow me to personalize the app to my liking. I enjoy it.",
	},
	{
		name: 'Vivek',
		time: 'September 7, 2024, 01:10',
		content: 'GB WhatsApp offers useful features, but it could use some performance optimizations for older devices.',
	},
	{
		name: 'Rina Sari',
		time: 'September 10, 2024, 04:49',
		content: 'The voice and video call quality in gb whatsapp is impressive. Clear sound and smooth video experience.',
	},
	{
		name: 'Priya',
		time: 'September 10, 2024, 12:45',
		content: "it's great.",
	},
	{
		name: 'Rajesh Patel',
		time: 'September 12, 2024, 06:20',
		content: "gb WhatsApp provides a good set of features. It's a convenient messaging app overall.",
	},
	{
		name: 'Siti Wahyuni',
		time: 'September 15, 2024, 10:55',
		content: "The latest update of gb whatsapp improved the performance and stability. It's working smoothly.",
	},
	{
		name: 'Siska Halim',
		time: 'September 20, 2024, 04:10',
		content: 'I appreciate the privacy options in gbwhatsapp. I feel more secure with my conversations.',
	},
	{
		name: 'Putri Sari',
		time: 'September 24, 2024, 11:45',
		content: 'I want to download gb whatsapp app.',
	},
	{
		name: 'Dewi Lestari',
		time: 'September 28, 2024, 04:20',
		content: 'I want to know how should I update my gb whatsapp.',
	},
	{
		name: 'Aashi Gupta',
		time: 'September 28, 2024, 11:10',
		content: "If I hide status, can people really not see if I'm online?",
	},
	{
		name: 'Rajiv Sharma',
		time: 'October 1, 2024, 05:30',
		content: 'I love this app.',
	},
	{
		name: 'Ashok Verma',
		time: 'October 3, 2024, 11:15',
		content: 'Good.',
	},
	{
		name: 'Saira Khan',
		time: 'October 5, 2024, 09:45',
		content: 'I want to share this app with my friends.',
	},
	{
		name: 'Saira Khan',
		time: 'October 7, 2024, 09:45',
		content: 'This app safe ? I want to use it.',
	},
	{
		name: 'Rani Indriani',
		time: 'October 10, 2024, 01:25',
		content: 'How to download this app.',
	},
	{
		name: 'Rahul',
		time: 'October 15, 2024, 09:50',
		content: 'gb whatsapp has a few minor bugs occasionally.',
	},
	{
		name: 'Neha Sharma',
		time: 'October 17, 2024, 01:15',
		content: "I'm satisfied with gb whatsapp.",
	},
	{
		name: 'Deepak Verma',
		time: 'October 18, 2024, 08:43',
		content: "gb whatsapp has a clean and user-friendly interface. It's easy to navigate and find what I need.",
	},
	{
		name: 'Adi Nugroho',
		time: 'October 19, 2024, 03:25',
		content: "I've recommended GB WhatsApp to my friends.",
	},
	{
		name: 'Dewi Kusuma',
		time: 'October 21, 2024, 11:05',
		content: 'Gbwhatsapp occasionally drains more battery than expected. Hoping for better optimization in the future updates.',
	},
	{
		name: 'Rajesh',
		time: 'October 23, 2024, 03:50',
		content: "Gbwhatsapp's chat backup feature is a lifesaver.",
	},
	{
		name: 'Priya Kapoor',
		time: 'October 23, 2024, 12:15',
		content: 'I appreciate the customization options in gb whatsapp. It allows me to personalize my chats.',
	},
	{
		name: 'Siti Rahayu',
		time: 'October 25, 2024, 03:45',
		content: 'I like the customization that gb whatsApp offers, being able to adjust the layout and colors of the chat interface myself.',
	},
	{
		name: 'Mason',
		time: 'October 29, 2024, 08:15',
		content: 'When will GBWhatsApp be updated to add new features?',
	},
	{
		name: 'Sophia',
		time: 'November 1, 2024, 09:00',
		content: 'Gb whatsapp is very good app.',
	},
	{
		name: 'Jaya Singhania',
		time: 'November 2, 2024, 08:05',
		content: 'I want to know how to use gb whatsapp channel.',
	},
	{
		name: 'Aryan Desai',
		time: 'November 4, 2024, 06:30',
		content: 'My friend told me that I can download safe gb whatsapp here.',
	},
	{
		name: 'Priya Mehra',
		time: 'November 6, 2024, 02:50',
		content: 'Good app gb whatsapp.',
	},
	{
		name: 'Adi Santoso',
		time: 'November 6, 2024, 04:00',
		content: 'This app safe ? I want to use it.',
	},
	{
		name: 'Aryan',
		time: 'November 8, 2024, 05:38',
		content: "Gb WhatsApp is my go-to messaging app. It's reliable, and I haven't faced any major issues.",
	},
	{
		name: 'Rahmat Suryanto',
		time: 'November 10, 2024, 11:15',
		content: 'The dark mode feature in GB WhatsApp is fantastic.',
	},
	{
		name: 'Sanjay',
		time: 'November 13, 2024, 03:52',
		content: "Gb WhatsApp's group chat functionality is great for coordinating with my friends.",
	},
	{
		name: 'Maya',
		time: 'November 18, 2024, 09:30',
		content: "Gb WhatsApp occasionally lags when handling a large number of chats, but it's still usable.",
	},
	{
		name: 'Aditya',
		time: 'November 21, 2024, 03:30',
		content: "Gb WhatsApp is my go-to messaging app. It's reliable and easy to use.",
	},
	{
		name: 'Ravi',
		time: 'November 24, 2024, 08:20',
		content: "It's been a smooth experience overall.",
	},
	{
		name: 'Faisal',
		time: 'November 26, 2024, 01:45',
		content: 'Gb WhatsApp offers a great set of features.',
	},
	{
		name: 'Nita',
		time: 'November 27, 2024, 07:10',
		content: 'I appreciate the privacy options in GB WhatsApp. It allows me to control who sees my profile.',
	},
	{
		name: 'Rahul',
		time: 'November 27, 2024, 11:55',
		content: "GB WhatsApp occasionally has minor glitches, but they're quickly resolved in updates.",
	},
	{
		name: 'Siti Wahyuni',
		time: 'November 28, 2024, 04:40',
		content: 'Gb WhatsApp has a user-friendly interface, making it easy to navigate and find what I need.',
	},
	{
		name: 'Vinod',
		time: 'December 2, 2024, 10:15',
		content: "I've recommended gb whatsapp to my friends.",
	},
	{
		name: 'Anjali',
		time: 'December 4, 2024, 03:50',
		content: 'The voice and video call quality in gbwhatsapp are impressive.',
	},
	{
		name: 'Endang Susilo',
		time: 'December 6, 2024, 09:36',
		content: "Gb WhatsApp's customization options allow me to personalize the app to my liking. I enjoy it.",
	},
	{
		name: 'Vivek',
		time: 'December 6, 2024, 11:12',
		content: 'GB WhatsApp offers useful features, but it could use some performance optimizations for older devices.',
	},
	{
		name: 'Kiran',
		time: 'December 9, 2024, 12:51',
		content: 'A reliable messaging app.',
	},
	{
		name: 'Sita',
		time: 'December 10, 2024, 05:25',
		content: "GB WhatsApp's file sharing feature simplifies sharing documents and media with my contacts.",
	},
	{
		name: 'Naina Kapoor',
		time: 'December 11, 2024, 11:10',
		content: "I find gb whatsapp's interface more intuitive compared to other messaging apps.",
	},
	{
		name: 'Meera',
		time: 'December 13, 2024, 11:05',
		content: 'GB WhatsApp has a great feature set.',
	},
	{
		name: 'Rini Widjaja',
		time: 'December 13, 2024, 14:32',
		content: 'I enjoy using it.',
	},
	{
		name: 'Arjun',
		time: 'December 16, 2024, 09:50',
		content: "I appreciate the ability to lock individual chats in gb whatsapp for added privacy. It's a useful feature.",
	},
	{
		name: 'Ananya',
		time: 'December 16, 2024, 11:14',
		content: 'The developers care about improving the user experience.',
	},
	{
		name: 'Imran',
		time: 'December 20, 2024, 08:40',
		content: 'I find it easy to use GB WhatsApp.',
	},
	{
		name: 'Kiran',
		time: 'December 21, 2024, 01:05',
		content: 'I love the ability to pin important chats in GB WhatsApp. It keeps my priority conversations at the top.',
	},
]

export { allCommentList }
