<template>
  <footer class="entry-footer">
    <p class="entry-meta">
      <span class="entry-categories">Filed Under: <a href="https://gbapksdownload.com/"
          rel="category tag">GBWhatsApp APK</a></span>
      <span class="entry-tags">Tagged With:
        <a href="https://gbapksdownload.com/gbwhatsapp.html" rel="tag">GBWhatsApp</a>,
        <a href="https://gbapksdownload.com/gbwhatsapp-apk/" rel="tag">GB WhatsApp apk download</a>,
        <a href="https://gbapksdownload.com/obwhatsapp-apk/" rel="tag">GB WhatsApp</a>,
        <a href="https://gbapksdownload.com/temporarily-banned/" rel="tag">WhatsApp gb</a>,
        <a href="https://gbapksdownload.com/yowhatsapp-apk/" rel="tag">GBWhatsApp update</a>,
        <a href="https://gbapksdownload.com/whatsapp-plus-apk/" rel="tag">GBWhatsApp pro</a>
      </span>
    </p>
  </footer>
</template>

<script>
export default {
  name: "tags",
};
</script>
