import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createRouterM from './router/'
import createStore from './store'
// 把 Vue Router 当前的 $route 同步为 Vuex 状态的一部分
import { sync } from 'vuex-router-sync'
import global from '@/utlis/global'
import UAParser from 'ua-parser-js'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
import { getMessaging, getToken } from 'firebase/messaging' //TODO:
import '@/components'
import { Dialog } from 'vant'
import 'vant/lib/dialog/style'
const firebaseConfig = {
	apiKey: 'AIzaSyB2YNPqcLLck0DP26-_1zEOqfjEU11toPs',
	authDomain: 'gbgw-f8992.firebaseapp.com',
	projectId: 'gbgw-f8992',
	storageBucket: 'gbgw-f8992.appspot.com',
	messagingSenderId: '844111971843',
	appId: '1:844111971843:web:4ebc61918482256e7b7036',
	measurementId: 'G-3MC33SVQ5S',
}
// Initialize Firebase
let apps = null,
	analytics = null,
	messaging = null //TODO:

// console.log(navigator.userAgent);

const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'

// console.log(deviceInfo, isMobileDevice)
isSupported().then((result) => {
	//TODO:
	if (result) {
		apps = initializeApp(firebaseConfig)
		analytics = getAnalytics(apps)
		logEvent(analytics, 'in_page_gb')
	}
})

// simple event

Vue.prototype.$global = global
Vue.prototype.$server = global.server
Vue.prototype.$Dialog = Dialog
Vue.prototype.$ua = {
	deviceInfo,
	isMobileDevice,
}
Vue.prototype.$logEvent = (event, params = {}) => {
	console.log(event)
	isSupported().then((result) => {
		if (result) {
			logEvent(analytics, event, params)
		}
	})
}

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	// 同步路由状态(route state)到 store
	sync(store, router)
	const app = new Vue({
		router,
		store,
		data() {
			return {
				initData: null,
				isMobileDevice: isMobileDevice,

				//广告
				adDes: null,
				isModalVisible: false,
				shouldRedirect: false,
			}
		},
		mounted() {
			this.initData = initData ? initData : { settings: { score: '3.54', appId: 'gbw', updateTime: 1670987497036, totalCount: 4567890 }, partners: [] }
			this.initializeFirebase() //TODO: Firebase 初始化和其它客户端特定的初始化可以放在这里
			this.getAD()
			this.setCanonicalURL()
		},
		methods: {
			setCanonicalURL() {
				const link = document.querySelector("link[rel='canonical']") || document.createElement('link')
				link.setAttribute('rel', 'canonical')
				link.setAttribute('href', this.$route.meta.canonical)
				document.head.appendChild(link)
			},
			getAD() {
				this.$server.getADetail().then((res) => {
					console.log(res)
					if (res.task) {
						this.adDes = res.task
						this.$logEvent(`show_centerbanner`)
					}
				})
			},
			//TODO:
			async initializeFirebase() {
				if (typeof window !== 'undefined' && (await isSupported())) {
					messaging = getMessaging(apps)
					this.requestNotificationPermission() // 请求通知权限
				}
			},
			requestNotificationPermission() {
				if (typeof window !== 'undefined' && 'Notification' in window) {
					// 此处确保代码只在客户端执行
					Notification.requestPermission().then((result) => {
						if (result === 'denied') {
							console.log('拒绝显示系统通知')
							this.$logEvent('game_outside_push_denied')
							return
						}
						if (result === 'default') {
							console.log('默认')
							return
						}
						console.log('允许显示系统通知')
						this.$logEvent('game_outside_push_permit')
						this.subscribeToPushNotifications()
					})
				} else {
					console.log('此环境不支持通知。')
				}
			},
			subscribeToPushNotifications() {
				console.log(messaging)
				getToken(messaging, { vapidKey: 'BCsE8EqYxwFsNjKBAad04VJ5eQVA3f9EIKFClLL4v6gnSZnkc-Tw1kyrFqAjckvBnGL_JiP9sZJV2ocZThZHQyQ' })
					.then((currentToken) => {
						if (currentToken) {
							console.log('设备令牌', currentToken)
							this.$logEvent('game_outside_success_init_user_token')
							this.sendPushNotification(currentToken)
						} else {
							console.log('无法获取设备令牌。')
						}
					})
					.catch((err) => {
						console.log('获取设备令牌时出错。', err)
					})
			},
			sendPushNotification(token) {
				const notification = {
					to: token,
					notification: {
						body: '新消息',
					},
				}
				console.log(notification)
			},
		},
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
