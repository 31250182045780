<template>
  <p>
    <img :class="size" :alt="alt" :src="require(`@/assets/${name}.webp`)" />
  </p>
</template>

<script>
// 默认宽图片,占满父亲宽度,传入图片名name="xx"无后缀 alt='' size="s"(s小号图片。默认撑满父亲50%)
export default {
    props: {
        name: {
            default: ''
        },
        alt: {
            default: ''
        },
        size: {
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
 @media screen and (orientation: portrait) {
    img {
        width: 100%;
    }
 }
 @media screen and (orientation: landscape) {
    img {
        width: 100%;
    }
    .s {
        width: 50%;
        margin: auto;
    }
 }
</style>