<template>
  <ul :class="{ dot }"  >
    <li v-for="(item, i) in arr" :key="i">
      <div v-for="(son, j) in item" :key="j">
        <strong style="font-size: 18px" v-if="j == 0">{{
          removeDashPrefix(son)
        }}</strong>
        <p v-else-if="typeof son === 'string'">
          {{ son }}
        </p>
        <!-- 默认全部使用窄距离图片 -->
        <myimg size="s" v-else :name="son.name" :alt="son.alt" />
      </div>
    </li>
  </ul>
</template>

<script>
// 传入格式:[['我是一个Li的标题','我是p1','我是p2',{name:'图片名不带后缀assets直接下面',alt:'xxx'}],['我是第二个li标题']]
// 直接穿入bgc表示有背景色
export default {
  props: {
    arr: {
      type: Array,
      default: [],
    },
    bgc: {
        default: false
    },
    //默认有圆点
    dot: {
      default: true,
    },
  },
  methods: {
    removeDashPrefix(str) {
      if (str.startsWith("- ")) {
        return str.slice(2);
      }
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
ul li::marker {
  color: 000000;
  font-size: 20px;
}
ul {
  margin: 20px;
  padding: 20px;
}
p {
  margin-top: 20px;
}
.dot {
  list-style-type: disc;
}

</style>