<template>
	<section id="section-2" class="section-content">
		<h2 class="intro-title intro-title-2" v-html="doc.s2.title_2"></h2>

		<p class="intro-item intro-item-3"><span @click="gotochat()" class="cursor-point">GBWhatsApp</span> offers customization features and so much more. Check the list down below, and we're sure that you'll be convinced of getting this mod for your device.</p>

		<div class="intro-li-row">
			<ul class="list-icons">
				<li v-for="(data, i) in doc.s2.ul" class="flexli" :key="i">
					<div class="list-head">
						<i class="fa"></i>
						<strong v-html="data.head"></strong>
					</div>
					<div class="list-detail" v-html="data.content"></div>
					<div class="list-img" v-if="data.img">
						<lazysource :source="data.img_webp" :img="data.img"></lazysource>
					</div>
				</li>
			</ul>
		</div>

		<h2 class="intro-title intro-title-3">
			GB WhatsApp Download and GB WhatsApp Update
		</h2>

		<h3 class="intro-title sub">WhatsApp GB Installation Guide </h3>

		<p class="intro-item intro-item-4">
			Now you want to download and install the <span @click="gotoorg()" class="cursor-point">GBWhatsApp MOD</span> version right? but it cannot be found on Google Play, so if you want to download GB WhatsApp, you will need to find it on a website and install it manually. If you are not familiar with the process of downloading the GB WhatsApp APK on your Android device, you can refer to this installation guide.

			<br>

			<b>Allow Unknown Sources:</b> Enable the unknown sources after opening the setting Tab. <br>
			<b>Download:</b> Click on the download button on top and download the APK file. <br>
			<b>Install:</b> After downloading, open the folder where you saved APK. Click on install button in the lower right corner.<br>
			<b>Launch:</b> Now you can launch the app.<br>
			<b>Login:</b> Login in the WhatsApp account and enjoy the fantastic features.
		</p>

		<p class="intro-item intro-item-5" v-html="doc.s2.intro_5"></p>

		<div class="advertisment-item adv-2">
			<lazysource :source="doc.s2.adimg_2_webp" :img="doc.s2.adimg_2"></lazysource>
		</div>

		<h3 class="intro-title sub">GB WhatsApp Update Guide </h3>

		<p class="intro-item intro-item-5">
			However, there are many friends has downloaded an older version of GB WhatsApp and wants to update it to the latest version, then you can follow these steps.
		</p>

		<ul class="intro-item intro-item-5 content-li">
			<li>
				First, ensure that you have downloaded the GB WhatsApp APK on your phone. Then, visit our official GB WhatsApp APK download website to obtain the installation file for the latest version. You can usually find the latest version on the homepage or the "Download" page of our website, our website always keeping the latest version.
			</li>
			<li>
				Once you have downloaded the file, open GB WhatsApp and go to the settings interface. Look for the "Version Info" option, where you can check the current version and see if an update is needed for GB WhatsApp. If an update is required, simply click on the "GB WhatsApp Update" button and wait for the installation file to finish downloading. After that, you will be prompted to install the latest version of GB WhatsApp. Click the "Install" button to initiate the installation update process. The duration of this process may vary depending on your device and network connection speed.
			</li>
			<li>
				Now, you will be able to use the latest version of GB WhatsApp, which includes updated features and improvements.
			</li>
		</ul>

		<p class="intro-item intro-item-5">
			Remember to always keep your GB WhatsApp updated to ensure optimal performance and security.
		</p>

		<h2 class="intro-title intro-title-3">WhatsApp APK</h2>

		<p class="intro-item ">I believe you have also heard that there are many modified versions of WhatsApp, such as GB WhatsApp Pro, FMWhatsApp, YoWhatsApp, WhatsApp Plus, etc. Like WhatsApp GB, they exist with richer functions than the official WhatsApp. </p>

		<p class="intro-item">So among them What&#39;s the difference? </p>

		<p class="intro-item intro-item-5">In fact, the difference between them is very small, and the most popular and feature-rich is GB WhatsApp, so you only need to choose GB WhatsApp.</p>

		<h2 class="intro-title intro-title-3">Conclusion</h2>

		<p class="intro-item intro-item-5">WhatsApp GB is loved and used by tens of thousands of users because of its rich functions. Most people use it without security problems, but no matter what, it is developed by third parties. It is recommended that if you want to download and use it, please be sure to abide by the official WhatsApp terms and conditions to avoid having your account blocked.</p>

	</section>
</template>

<script>
import lazysource from "@/components/lazysource.vue";

export default {
	name: "s2",
	components: {
		lazysource,
	},
	props: {
		doc: Object,
	},
	methods: {
		gotochat() {
			window.location.href = "https://www.whatsplus.site/";
		},
		gotoorg() {
			window.location.href = "https://www.whatspro.org/";
		},
	},
};
</script>
