<template>
	<div class="slide-down-row">
		<transition name="slide">
			<div v-show="isVisible" class="sliding-div" :style="{ backgroundImage: 'url(' + $root.adDes.bgd_url + ')', backgroundSize: 'cover' }" :class="{'withbg':$root.adDes.bgd_url}" @click="gotoAD($root.adDes.jumpUrl)" v-if="$root.adDes">
				<div class="ad-icon">AD</div>
				<div class="ad-intro-row">
					<div class="ad-intro-icon" v-if="$root.adDes && $root.adDes.icon_url">
						<img :src="$root.adDes.icon_url" :alt="$root.adDes.title || ''"  alt="">
					</div>
					<div class="ad-intro-content">
						<div class="ad-intro-name" v-if="$root.adDes && $root.adDes.title" :class="{'nobg':!$root.adDes.bgd_url}" v-html="$root.adDes.title"></div>
						<div class="ad-intro-contex" :class="{'nobg':!$root.adDes.bgd_url}" v-html="$root.adDes.desc" v-if="$root.adDes && $root.adDes.desc"></div>
					</div>
				</div>

				<div class="ad-intro-btn" v-html="$root.adDes.btn" v-if="$root.adDes && $root.adDes.btn"></div>

			</div>
		</transition>
		<div @click="toggleDiv" class="toggleDiv">
			<div class="up arrow" v-show="buttonPosition == 'up'">
				<img src="@/assets/arr-up.png" alt="">
			</div>
			<div class="down arrow" v-show="buttonPosition == 'down'">
				<img src="@/assets/arr-down.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isVisible: false, // 控制div的显示和隐藏
			buttonPosition: "up",
		};
	},
	mounted() {
		this.isVisible = true;
	},
	computed: {
		buttonContainerStyle() {
			return {
				"z-index": "99999",
				// top: this.isVisible ? "200px" : "0", // 当div可见时按钮在div下方200px处，否则紧贴顶部
				// left: "50%",
				transform: "translateX(-50%)",
				transition: "top 0.5s ease",
			};
		},
	},
	methods: {
		toggleDiv() {
			this.isVisible = !this.isVisible;
			this.isVisible
				? (this.buttonPosition = "up")
				: (this.buttonPosition = "down");
		},
		gotoAD(data) {
			this.$logEvent(`click_slide_banner`);
			window.location.href = data;
		},
	},
};
</script>
