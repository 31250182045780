import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/gb/index.vue'
import UAParser from 'ua-parser-js'
const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
  {
    path: '/',
    name: 'Home',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/', // 添加规范 URL
    },
    component: Home,
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: false,
      canonical: 'https://gbapksdownload.com/download', // 添加规范 URL
    },
    component: () => import('@/components/download.vue'),
  },
  {
    path: '/gbwhatsapp.html',
    name: 'gbwhatsapp-html',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp.html', // 添加规范 URL
    },
    // component: () => import('@/views/gb/page/pcnew.vue'),
    component: Home,
  },
  {
    path: '/obwhatsapp.html',
    name: 'obwhatsapp-html',
    meta: {
      appname: 'OBWhatsApp',
      topLogo: require('@/assets/ob/logo@2x.png'),
      footLogo: require('@/assets/ob/GBWhatsApp.png'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/obwhatsapp.html', // 补充规范 URL
    },
    component: () => import('@/views/ob/pc.vue'),
  },
  {
    path: '/yowhatsapp.html',
    name: 'yowhatsapp-html',
    meta: {
      appname: 'YoWhatsApp',
      topLogo: require('@/assets/yo/logo@2x.png'),
      footLogo: require('@/assets/yo/footLogo.png'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/yowhatsapp.html', // 补充规范 URL
    },
    component: () => import('@/views/yo/pc.vue'),
  },
  {
    path: '/whatsapp2plus.html',
    name: 'whatsapp2plus-html',
    meta: {
      appname: 'WhatsApp Plus',
      topLogo: require('@/assets/plus/logo@2x.png'),
      footLogo: require('@/assets/plus/GBWhatsApp.png'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/whatsapp2plus.html', // 补充规范 URL
    },
    component: () => import('@/views/plus/pc.vue'),
  },
  {
    path: '/dmca',
    name: 'dmca',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'DMCA Policy',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/dmca', // 补充规范 URL
    },
    component: () => import('@/views/DMCA.vue'),
  },
  {
    path: '/blogs',
    name: 'blogs',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Blogs',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/blogs', // 补充规范 URL
    },
    component: () => import('@/views/blog.vue'),
  },
  {
    path: '/gbwhatsapp-apk',
    name: 'gbwhatsapp-apk',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: false,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/gb/page/0712.vue'),
  },
  {
    path: '/gbwhatsapp-apk-download',
    name: 'gbwhatsapp-apk-download',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp-apk-download', // 补充规范 URL
    },
    component: () => import('@/views/gb/page/pcnew.vue'),
  },
  {
    path: '/gbwhatsapp-apk-blog',
    name: 'gbwhatsapp-apk-blog',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'GB WhatsApp and WhatsApp spy',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp-apk-blog', // 补充规范 URL
    },
    component: () => import('@/views/blogs/gb-1.vue'),
  },
  {
    path: '/obwhatsapp-apk',
    name: 'obwhatsapp-apk',
    meta: {
      appname: 'OBWhatsApp',
      breadcrumbName: 'Features of OB WhatsApp',
      topLogo: require('@/assets/ob/logo@2x.png'),
      footLogo: require('@/assets/ob/GBWhatsApp.png'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/obwhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/blogs/ob-1.vue'),
  },
  {
    path: '/whatsapp-plus-apk',
    name: 'whatsapp-plus-apk',
    meta: {
      appname: 'WhatsApp Plus',
      breadcrumbName: 'What is WhatsApp Plus?',
      topLogo: require('@/assets/plus/logo@2x.png'),
      footLogo: require('@/assets/plus/GBWhatsApp.png'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/whatsapp-plus-apk', // 补充规范 URL
    },
    component: () => import('@/views/blogs/plus-1.vue'),
  },
  {
    path: '/yowhatsapp-apk',
    name: 'yowhatsapp-apk',
    meta: {
      appname: 'YoWhatsApp',
      breadcrumbName: 'What is Yo WhatsApp?',
      topLogo: require('@/assets/yo/logo@2x.png'),
      footLogo: require('@/assets/yo/footLogo.png'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/yowhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/blogs/yo-1.vue'),
  },
  {
    path: '/temporarily-banned',
    name: 'temporarily-banned',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Temporarily Banned',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/temporarily-banned', // 补充规范 URL
    },
    component: () => import('@/views/blogs/banned.vue'),
  },
  {
    path: '/whatsapp-channels',
    name: 'whatsapp-channels',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'WhatsApp Channels',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/whatsapp-channels', // 补充规范 URL
    },
    component: () => import('@/views/blogs/plus-2.vue'),
  },
  {
    path: '/download-gbwhatsapp-apk',
    name: 'download-gbwhatsapp-apk',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Temporarily Banned',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/download-gbwhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/gb/page/pcredirect.vue'),
  },
  {
    path: '/transfer',
    name: 'transfer',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Temporarily Banned',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/transfer', // 补充规范 URL
    },
    component: () => import('@/views/transfer.vue'),
  },
]

export const constRoutesM = [
  {
    path: '/',
    name: 'Home',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/', // 添加规范 URL
    },
    component: Home,
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: false,
      canonical: 'https://gbapksdownload.com/download', // 添加规范 URL
    },
    component: () => import('@/components/download.vue'),
  },
  {
    path: '/gbwhatsapp.html',
    name: 'gbwhatsapp-html',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp.html', // 添加规范 URL
    },
    // component: () => import('@/views/gb/page/mobilenew.vue'),
    component: Home,
  },
  {
    path: '/obwhatsapp.html',
    name: 'obwhatsapp-html',
    meta: {
      appname: 'OBWhatsApp',
      topLogo: require('@/assets/ob/logo@2x.png'),
      footLogo: require('@/assets/ob/GBWhatsApp.png'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/obwhatsapp.html', // 补充规范 URL
    },
    component: () => import('@/views/ob/pc.vue'),
  },
  {
    path: '/yowhatsapp.html',
    name: 'yowhatsapp-html',
    meta: {
      appname: 'YoWhatsApp',
      topLogo: require('@/assets/yo/logo@2x.png'),
      footLogo: require('@/assets/yo/footLogo.png'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/yowhatsapp.html', // 补充规范 URL
    },
    component: () => import('@/views/yo/pc.vue'),
  },
  {
    path: '/whatsapp2plus.html',
    name: 'whatsapp2plus-html',
    meta: {
      appname: 'WhatsApp Plus',
      topLogo: require('@/assets/plus/logo@2x.png'),
      footLogo: require('@/assets/plus/GBWhatsApp.png'),
      showBreadcrumb: true,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/whatsapp2plus.html', // 补充规范 URL
    },
    component: () => import('@/views/plus/pc.vue'),
  },
  {
    path: '/dmca',
    name: 'dmca',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'DMCA Policy',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/dmca', // 补充规范 URL
    },
    component: () => import('@/views/DMCA.vue'),
  },
  {
    path: '/blogs',
    name: 'blogs',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Blogs',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/blogs', // 补充规范 URL
    },
    component: () => import('@/views/blog.vue'),
  },
  {
    path: '/gbwhatsapp-apk',
    name: 'gbwhatsapp-apk',
    meta: {
      appname: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: false,
      showTelegram: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/gb/page/m-0712.vue'),
  },
  {
    path: '/gbwhatsapp-apk-download',
    name: 'gbwhatsapp-apk-download',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'GBWhatsApp',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp-apk-download', // 补充规范 URL
    },
    component: () => import('@/views/gb/page/mobilenew.vue'),
  },
  {
    path: '/gbwhatsapp-apk-blog',
    name: 'gbwhatsapp-apk-blog',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'GB WhatsApp and WhatsApp spy',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/gbwhatsapp-apk-blog', // 补充规范 URL
    },
    component: () => import('@/views/blogs/gb-1.vue'),
  },
  {
    path: '/obwhatsapp-apk',
    name: 'obwhatsapp-apk',
    meta: {
      appname: 'OBWhatsApp',
      breadcrumbName: 'Features of OB WhatsApp',
      topLogo: require('@/assets/ob/logo@2x.png'),
      footLogo: require('@/assets/ob/GBWhatsApp.png'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/obwhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/blogs/ob-1.vue'),
  },
  {
    path: '/whatsapp-plus-apk',
    name: 'whatsapp-plus-apk',
    meta: {
      appname: 'WhatsApp Plus',
      breadcrumbName: 'What is WhatsApp Plus?',
      topLogo: require('@/assets/plus/logo@2x.png'),
      footLogo: require('@/assets/plus/GBWhatsApp.png'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/whatsapp-plus-apk', // 补充规范 URL
    },
    component: () => import('@/views/blogs/plus-1.vue'),
  },
  {
    path: '/yowhatsapp-apk',
    name: 'yowhatsapp-apk',
    meta: {
      appname: 'YoWhatsApp',
      breadcrumbName: 'What is Yo WhatsApp?',
      topLogo: require('@/assets/yo/logo@2x.png'),
      footLogo: require('@/assets/yo/footLogo.png'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/yowhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/blogs/yo-1.vue'),
  },
  {
    path: '/temporarily-banned',
    name: 'temporarily-banned',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Temporarily Banned',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/temporarily-banned', // 补充规范 URL
    },
    component: () => import('@/views/blogs/banned.vue'),
  },
  {
    path: '/whatsapp-channels',
    name: 'whatsapp-channels',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'WhatsApp Channels',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/whatsapp-channels', // 补充规范 URL
    },
    component: () => import('@/views/blogs/plus-2.vue'),
  },
  {
    path: '/download-gbwhatsapp-apk',
    name: 'download-gbwhatsapp-apk',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Temporarily Banned',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/download-gbwhatsapp-apk', // 补充规范 URL
    },
    component: () => import('@/views/gb/page/mobileredirect.vue'),
  },
  {
    path: '/transfer',
    name: 'transfer',
    meta: {
      appname: 'GBWhatsApp',
      breadcrumbName: 'Temporarily Banned',
      topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
      footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
      showBreadcrumb: true,
      canonical: 'https://gbapksdownload.com/transfer', // 补充规范 URL
    },
    component: () => import('@/views/transfer.vue'),
  },
]

var routes = []
if (isMobileDevice) {
  routes = constRoutesM
}
if (!isMobileDevice) {
  routes = constRoutesPc
}

export default function createRouter() {
  return new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
  })
}
