import axios from 'axios' //引入axios
// 设置post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.withCredentials = false //在跨域请求时，不会携带用户凭证；返回的 response 里也会忽略 cookie

//创建axios实例，请求超时时间为300秒
const instance = axios.create({
	timeout: 300000,
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
})

// 存储pengding请求容器
const pendingList = new Map()
// 生成各个请求标识
const getFetchKey = (config) => {
	const { url, data, method, params } = config
	let token
	if (method === 'get') {
		token = [method, url, JSON.stringify(params)].join('&')
	} else {
		token = [method, url, JSON.stringify(data)].join('&')
	}
	// console.log(`fetch token ${token}`);
	return token
}
const addPending = (config) => {
	const fetchKey = getFetchKey(config)
	if (fetchKey) {
		config.cancelToken =
			config.cancelToken ||
			new axios.CancelToken((cancel) => {
				if (!pendingList.has(fetchKey)) {
					pendingList.set(fetchKey, cancel)
				}
			})
	}
}
// 移除pengding请求
const removePending = (config) => {
	const fetchKey = getFetchKey(config)
	if (fetchKey) {
		if (pendingList.has(fetchKey)) {
			pendingList.delete(fetchKey)
		}
	}
}
// 取消请求
const cancelPending = (config) => {
	const fetchKey = getFetchKey(config)
	if (fetchKey) {
		if (pendingList.has(fetchKey)) {
			console.warn(`${config.url}: 短时间内重复请求`)
			const cancel = pendingList.get(fetchKey)
			cancel('cancel')
			pendingList.delete(fetchKey)
		}
	}
}

//请求和响应拦截可以根据实际项目需求进行编写
// 请求发起前拦截
instance.interceptors.request.use(
	(request) => {
		//这里可以对接口请求头进行操作 如：request.headers['X-Token'] = token
		// console.log('请求拦截', request)
		// console.log(request.header);
		request.headers = { ...request.header }
		// request.headers = { ...request.header,'x-forwarded-for':'210.212.145.105' }
		// 发送请求前首先检查该请求是否已经重复，重复则进行取消并移除
		cancelPending(request)
		// 添加该请求到pendingList中
		addPending(request)

		return request
	},
	(error) => {
		return Promise.reject(error)
	}
)

// 响应拦截（请求返回后拦截）
instance.interceptors.response.use(
	(response) => {
		// console.log('响应拦截', response)
		removePending(response)
		return response.data
	},
	(error) => {
		removePending(error.response)
		return Promise.reject(error)
	}
)

//按照请求类型对axios进行封装
const service = {
	get(url, data) {
		return instance.get(url, { params: data })
	},
	post(url, obj) {
		return instance.post(url, obj)
	},
	gate(url, obj) {
		return instance.post(url, obj.data, obj)
	},
}
export { service }
