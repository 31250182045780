<template>
	<section id="section-3" class="section-content">
		<!-- <h2 class="intro-title intro-title-4" v-html="doc.s2.title_4"></h2>

		<mods :doc="doc" :name="pageName" :mods="data" v-for="(data,i) in adList" :key="i"></mods> -->

	</section>
</template>

<script>
import mods from "@/components/mods.vue";

export default {
	name: "s3",
	props: {
		doc: Object,
		pageName: String,
	},
	components: {
		mods,
	},
	data() {
		return {
			adList: null,
		};
	},
	beforeMount() {
		// this.getList();
	},
	methods: {
		getList() {
			this.$server
				.getAppList({
					appId: "com.gbwhatsapp",
					country: "ID",
				})
				.then((res) => {
					console.log(res);
					this.adList = res.data;
				});
		},
	},
};
</script>
