<template>
    <div class="container">
        <div v-for="(item, index) in arr" :key="item[0]" class="faq-item">
          <button
            class="faq-title"
            @click="
              faqFlags[index] = !faqFlags[index];
              $forceUpdate();
            "
          >
            {{ item[0] }}
            <span>
              <svg
                v-if="!faqFlags[index]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                  fill="currentColor"
                />
              </svg>
              <svg
                v-else
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 13H5V11H19V13Z" fill="currentColor" />
              </svg>
            </span>
          </button>
          <div class="faq-content" :class="{ active: faqFlags[index] }">
            <p>{{ item[1] }}</p>
          </div>
        </div>
      </div>
</template>

<script>
// 可折叠的问题与回答
// 传入二维数组，【【问题1，回答1】，【问题2，回答2】】
export default {
    props: {
        arr: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            faqFlags: [],
        }
    },
    created () {
        this.arr.map((e) => this.faqFlags.push(false));
    }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  padding: 20px;
}
.faq-item {
  flex-direction: column;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  overflow: hidden;
  button {
    display: flex;
    justify-content: space-between;
    background: #131b21;
  }
}
.faq-title {
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  text-align: left;
  width: 100%;
}
.faq-content {
  display: none;
  padding: 15px;
}
.faq-item .faq-content.active {
  display: block;
}


</style>