<template>
  <div class="fa" >
    <div class="top" @click="ifShow = !ifShow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M3 10h10v1H3v-1zm0-3h10v1H3V7zm0-3h10v1H3V4z" />
      </svg>
      <h2>Table of Contents</h2>
    </div>

    <div style="margin-top: 20px;" class="content" v-show="ifShow  ">
      <a :href="`#d${i + 1}`" v-for="(data, i) in arr" :key="i">{{ data }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    arr: {
      default: [],
    },
 
  },
  data () {
    return {
        ifShow: true
    }
  },
  mounted () {
    if (this.arr.length>5) {
        this.ifShow = false
    }
  }

};
</script>

<style lang="scss" scoped>
.fa {
    user-select: none;
  .top {
    &:hover {
        cursor: pointer;
    }
    font-size: 25px;
    font-weight: 500;
    align-items: center;
    display: flex;
    
    svg {
      margin-right: 10px;
    }
    h2 {
      margin: 0;
    }
  }
  background: #f7f7f7;
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  margin: 20px auto;
  a {
    line-height: 50px;
    display: block;
    font-size: 18px;
    color: #00745e;
  }
}
@media screen and (orientation: portrait) {
   .fa a {
        line-height: 24px;
        margin-bottom: 20px;
    }
}

</style>