<template>
  <div class="copy-right">
    © 2024 gbapksdownload.com - All Rights Reserved.
  </div>
</template>

<script>
export default {
  name: "Copy",
};
</script>
