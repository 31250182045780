<template>
	<div id="footer" class="footer">
		<div class="footer-row">
			<img :src="appLogo" class="footer-img" alt="logo" />
			<div class="footer-name">
				{{ appName }}
			</div>
		</div>

		<div class="cooperat-wrapper">
			<div class="cooperat-head">About</div>
			<div class="cooperat-row">
				<a href="/dmca" class="cooperat-item">DMCA</a>
			</div>
		</div>

		<div class="cooperat-wrapper" v-if="partners && partners.length">
			<div class="cooperat-head">Cooperative Partner</div>
			<div class="cooperat-row">
				<a :href="data.link" class="cooperat-item" v-for="(data, i) in partners" :key="i">{{ data.name }}</a>
			</div>
		</div>


		<div class="contact-wrapper">
			<div class="contact-head">Contact</div>
			<div class="contact-row">
				gbwhatsapp.urimods@gmail.com
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			partners: [
				{
					name: "whatsplus.online",
					link: "https://www.whatsplus.online/"
				},
				{
					name: "androidshub.com",
					link: "https://www.androidshub.com/"
				}
			]
		};
	},
	computed: {
		partners() {
			return this.$root.initData &&
				this.$root.initData.partners
				? this.$root.initData.partners
				: [];
		},
	},
	props: {
		appName: String,
		appLogo: String,
	},
	methods: {
		goto(link) {
			window.location.href = link;
		},
	},
};
</script>
