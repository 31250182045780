export default {
	s1: {
		h1: 'Download the Latest WhatsApp Plus APK (2024) for Enhanced Messaging',
		recommend1: 'Security verified',
		recommend2: 'Official certification',
		adimg_1: require('@/assets/gb/p-1.webp'),
		intro_1: 'WhatsApp Plus is the modified version of the official WhatsApp. You get many extra features with WhatsApp Plus instead of using the official WhatsApp. Features like hiding online status, airplane mode are the core benefits of WhatsApp Plus. It has extra privacy available in it, and there is no need to pay anything to use this mod.',
		title_1: 'WhatsApp Plus vs WhatsApp – What\'s The Difference?',
		intro_2: 'There are many changes in WhatsApp Plus instead of WhatsApp. Like good-looking themes availability, hide online status, turn on airplane mode, etc. Below is a list of some of the main key features of WhatsApp Plus VS WhatsApp.',
	},
	apkdetail: {
		title_1: 'WhatsApp Plus Download APK',
	},
	s2: {
		title_2: 'Summary of WhatsApp Plus APK features ',
		intro_3: 'WhatsApp Plus offers customization features and so much more. Check the list down below, and we\'re sure that you\'ll be convinced of getting this mod for your device.',
		adimg_1: require('@/assets/plus/ad1.webp'),
		ul: [
			{ head: 'DND Mode', content: 'Disable internet access for WhatsApp Plus without affecting other apps, allowing you to focus without interruptions.' },
			{ head: 'Auto Reply', content: 'Set up automatic responses to messages when you are unavailable, ensuring you stay connected even when busy.'},
			{ head: 'Go to First Message', content: 'Quickly navigate to the first message in any chat, saving time when revisiting old conversations.' },
			{ head: 'Message Filtering', content: 'Organize your chats by filtering messages, making it easier to manage conversations.' },
			{ head: 'Unlimited Themes', content: 'Customize the app\'s appearance with a wide range of themes and emojis to suit your personal style.', img: require('@/assets/gb/ulcontent-2.webp'), alt: 'WhatsApp Plus Themes' },
			{ head: 'File Sharing', content: 'Share large files, including videos and audio up to 1GB, and send up to 100 images at once.' },
			{ head: 'Broadcast Texts', content: 'Send broadcast messages to groups, a feature that\'s useful for sharing updates quickly.' },
			{ head: 'Contact Customization', content: 'Manage the visibility of media from specific contacts in your gallery.' },
			{ head: 'Anti-Delete Messages', content: 'With the anti-revoke feature, you\'ll be able to see messages even if the sender tries to delete them.', img: require('@/assets/gb/ulcontent-3.webp'), alt: 'disable forwarded' },
			{ head: 'Unread Messages', content: 'Mark messages as unread from the notification panel, making it easier to keep track of important conversations.' },
			{ head: 'Save Status', content: 'Download photos and videos from your contacts\' statuses directly to your phone.' },
			{ head: 'Custom Fonts', content: 'Personalize your chats with a variety of fonts, adding a unique touch to your conversations.' },
			{ head: 'Special Effects', content: 'Add fun effects to photos and videos before sending them, making your media stand out.', img: require('@/assets/gb/ulcontent-4.webp'), alt: 'Special Effects' },
			{ head: 'High-Quality Images', content: 'Send images without compression, ensuring your pictures retain their original quality.' },
			{ head: 'Language Translation', content: 'Translate messages into your preferred language directly in the app, perfect for multilingual conversations.' },
			{ head: 'Select All Chats', content: 'Select all chats at once from the home screen for quick and easy management.' },
			{ head: 'Pin Messages', content: 'Pin important messages in private chats for fast access later.' },
		],
		new: [
			"Enhanced anti-ban protection",
			"Fixed some bugs",
			"Added a feature: Go to the First Message",
			"Language Translation: added more languages",
			"Added guide to use Link as Companion Device"
		],		

		title_3: 'Installation guide',
		intro_4: 'You can not find the WhatsApp Plus MOD version on Google Play, so you need to download it from the site and then install it manually. If you do not know how to install WhatsApp Plus on your Android you may follow this installation guide.',
		intro_5: 'To install an APK file into the operating system of your Android, you may need to turn on the \'Unknown sources\', located in settings>security>unknown sources. Then just follow these steps with images as shown below.',
		adimg_2: require('@/assets/gb/ad4plus.webp'),
		title_4: 'Download All Latest Mod for Android Directly (Officially)',
	},
	s3: {
		title_6: 'More Android Mods',
		title_7: 'Android Games',
		download_btn: 'DOWNLOAD',
		big_btn: 'Load More Android Updates',
	},
	s4: {
		title_5: 'The 4 best tools make WhatsApp more useful & poewrful.',
	},
}
