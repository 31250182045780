<template>
	<div :class="$store.state.deviceType === 'desktop' ? 'is_pc i_pc': 'is_mobile i_m'" id="app">
		<to-top class="totop" />
		<slide-down-div v-show="showSlideDown" v-if="$root.adDes && _isMobile()"></slide-down-div>

		<!-- <Modal :isVisible="$root.isModalVisible" @close="handleClose" /> -->

		
		<TopheadM class="m" v-if="_isMobile()" :class="$route.meta.appname.toLowerCase()" :appName="$route.meta.appname" :appLogo="$route.meta.topLogo" />
		<Tophead class="pc" v-if="!_isMobile()" :class="$route.meta.appname.toLowerCase()" :appName="$route.meta.appname" :appLogo="$route.meta.topLogo" />

		<!-- <div v-if="$route.meta.showBreadcrumb" class="bread-cast-row">Latest GB & PLUS BLOG > {{ $route.meta.breadcrumbName || $route.meta.appname }}</div>
		<div class="bread-cast-row" v-else></div> -->

		<router-view :class="$route.meta.appname.toLowerCase()" />

		<Footr :app-name="$route.meta.appname" :app-logo="$route.meta.footLogo" />
		<!-- <Tags /> -->
		<copy />

		<div class="telegram-row" v-show="$route.meta.showTelegram">
			<div class="telegram-item" @click="gotoTele()">
				<span class="telegram-num"></span>
			</div>
			<div class="telegram-msg" v-show="showTeleMsg">Join Our Telegram Channel</div>
		</div>

	</div>
</template>


<script>
import "@/css/main.scss";
import "@/css/mobile/common.scss";
import "@/css/pc/common.scss";
import TopheadM from "@/components/mobile/Tophead.vue";
import Tophead from "@/components/pc/Tophead.vue";
import Footr from "@/components/Footr.vue";
import Tags from "@/components/tags.vue";
import copy from "@/components/copyright.vue";
import ClientOnly from "vue-client-only";

import SlideDownDiv from "@/components/mobile/slidDown.vue";
import Modal from "@/components/mobile/modal.vue";
import ToTop from './components/toTop.vue';


export default {
	name: "app",
	components: {
		Tophead,
		TopheadM,
		Footr,
		Tags,
		copy,
		ClientOnly,

		SlideDownDiv,
		Modal,
		ToTop
	},
	created () {
		const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		if (flag) {
			this.$store.state.deviceType = 'mobile'
		} else {
			this.$store.state.deviceType = 'desktop'
		}
	},
	data() {
		return {
			myApp: null,
			screenWidth: null,
			is_mobile: null,
			toplogo: require("@/assets/gb/GB_new_icon_small@2x.webp"),
			footlogo: require("@/assets/gb/GB_new_icon_small@2x.webp"),
			showTeleMsg: true,


			showSlideDown: false,
		};
	},
	computed: {
		deviceType() {
			// 在服务器端渲染期间，从Vue SSR上下文中获取设备类型
			if (this.$ssrContext) {
				console.log("ssr");
				return this.$ssrContext.deviceType;
			}

			// 在客户端渲染期间，使用窗口宽度检测设备类型
			const windowWidth =
				window.innerWidth || document.documentElement.clientWidth;
			return windowWidth < 768 ? "mobile" : "desktop";
		},
		isMobile() {
			return this.deviceType === "mobile";
		},
	},
	mounted() {
		console.log(process.env);
		this.myApp = this.$store.state.gb;
		this.$logEvent("show_gbhome");

		setTimeout(() => {
			this.showTeleMsg = false;
			this.showSlideDown = true
			this.$logEvent("show_slide_banner");
		}, 2000);
	},

	methods: {
		handleClose() {
			this.$root.isModalVisible = false;
			this.$root.shouldRedirect = true;
			console.log(this.$root.shouldRedirect);
		},
		_isMobile() {
			if (typeof navigator !== "undefined") {
				// 在浏览器环境中执行的代码
				// 使用 navigator 对象进行相关操作
				return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				);
			} else {
				// 在服务器环境中执行的代码
				// 忽略或提供替代方案
				return false;
			}
		},
		gotoTele() {
			if (typeof window !== "undefined") {
				// 在浏览器环境中执行的代码
				window.location.href = "https://t.me/gb_download";
			}
		},
	},
};
</script>
